import { type Params } from 'react-router-dom';
import { type Analytic } from 'types/analytic';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export const getAnalytic = async (params: Params): Promise<Analytic> => {
  const response = await getRequest(`/api/v1/analytics/${params.analyticId}`);

  return StringUtil.snakeObjectToCamelObject(await response.json()) as Analytic;
};
